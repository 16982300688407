.App {
  font-family: Helvetica, sans-serif;
}

.App-header {
  background-color: #282c34;
  min-height: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

form {border: 10px solid #f1f1f1;}

input[type=text], input[type=password] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

button {
  background-color: #04AA6D;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
}

button:hover {
  opacity: 0.8;
}

.container {
  padding: 40px;
}

span {
  font-size: 12px;
}

/* 랜딩페이지 */ 
.notice_popup_wrap{height:100%;padding-bottom:241px;background:#f5f7f9}
.notice_popup_wrap .system_layer_pop{position:relative;top:50%;left:50%;width:951px;height:483px;margin: 0 0 0 -475px;background:#fff;color:#28313d;text-align:center}
.notice_popup_wrap .system_layer_pop .layer_pop_bx{display:table-cell;width:951px;height:473px;vertical-align:middle}
.notice_popup_wrap .system_layer_pop .tit{font-weight:700;font-size:36px}
.notice_popup_wrap .system_layer_pop .tit span{color:#3a76cb;font-size:36px}
.notice_popup_wrap .system_layer_pop .copy_text{margin:35px 0;font-size:18px;line-height:28px}